<template>
  <div class="filingALawsuitListContainer">
    <div class="fAListTitle">
      {{ material_type.desc }}
    </div>
    <div class="fAListBody">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了~" @load="onLoad">
        <div class="fAListBodyItemContainer" v-for="(item, index) in goodList" :key="index"
          @click.stop="goLitigationDetails(item)">
          <div class="fAListBodyItemTitle">
            <div class="fAListBodyItemTitleLeft">
              诉讼案件号：{{ item.lawsuit_no }}
            </div>
            <img src="@/static/images/righticon@2x.png" alt="" srcset="" class="righticon" />
          </div>
          <div class="fAListBodyItemBody">
            <div class="defendantContainer">
              <div class="defendantNameTitle">被告姓名：</div>
              <div class="defendantName">{{ item.real_name }}</div>
            </div>
            <div class="identityCardContainer">
              <div class="defendantNameTitle">身份证号：</div>
              <div class="defendantName">{{ item.identity_card_number }}</div>
            </div>
            <div class="identityCardContainer">
              <div class="defendantNameTitle">索赔金额：</div>
              <div class="defendantName">{{ item.lawsuit_amount }}元</div>
            </div>
            <img src="@/static/images/label.png" alt="" class="defendantLogo" />
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getMaterialList } from "@/api/security";
export default {
  name: "FilingALawsuitList",
  props: ["is_navbar"],
  data() {
    return {
      goodList: [],
      //   是否处于加载状态，加载过程中不触发load事件
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      type: 2,
      material_type: {},
    };
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
  },
  methods: {
    onLoad() {
      let config = {
        type: this.type,
        page: this.page,
        page_size: this.page_size,
      };
      this.requestGetMaterialList(config);
    },
    // 立案诉讼详情
    goLitigationDetails(item) {
      this.$router.push({
        name: "newLitigationDetails",
        query: {
          id: item.id,
          is_navbar: this.is_navbar,
        },
      });
    },
    // 商品列表请求
    async requestGetMaterialList(config) {
      this.loading = true;
      try {
        const result = await getMaterialList(config);
        if (result.code === 20000 || result.status_code === 1000) {
          this.material_type = result.data.material_type;
          let goodList = [...this.goodList, ...result.data.data];
          let newobj = {};
          // 去重
          this.goodList = goodList.reduce((preVal, curVal) => {
            newobj[curVal.id] ? "" : (newobj[curVal.id] = preVal.push(curVal));
            return preVal;
          }, []);
          // 没有数据了
          if (result.data.data.length < this.page_size) {
            this.finished = true;
          } else {
            this.page += 1;
            this.loading = false;
          }
          if (this.page === 1 && result.data.data.length === 0) {
            this.defaultPage = true;
          } else {
            this.defaultPage = false;
          }
          // 缺省页
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.filingALawsuitListContainer {
  width: 375px;
  padding: 0 15px;
  box-sizing: border-box;
  .fAListTitle {
    width: 355px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 20px;
    padding-bottom: 10px;
  }

  .fAListBody {
    .fAListBodyItemContainer {
      margin: 0 auto;
      padding: 0 5px;
      box-sizing: border-box;
      width: 350px;
      height: 120px;
      background: #F6F7F8;
      border-radius: 8px;
      margin-bottom: 10px;

      .fAListBodyItemTitle {
        width: 100%;
        height: 39px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 14px 0px 10px;
        border-bottom: 1px solid #E5E5E5;
        box-sizing: border-box;

        .fAListBodyItemTitleLeft {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222;
          line-height: 20px;
        }

        .righticon {
          width: 16px;
          height: 20px;
        }
      }

      .fAListBodyItemBody {
        width: 100%;
        height: 100px;
        padding: 12px;
        box-sizing: border-box;
        position: relative;

        .defendantContainer {
          display: flex;
          align-items: center;

          .defendantNameTitle {
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            text-align: left;
            font-style: normal;
          }

          .defendantName {
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            text-align: left;
            font-style: normal;
          }
        }

        .identityCardContainer {
          display: flex;
          align-items: center;
          margin-top: 4px;

          .defendantNameTitle {
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            text-align: left;
            font-style: normal;
          }

          .defendantName {
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            text-align: left;
            font-style: normal;
          }
        }

        .defendantLogo {
          width: 88px;
          height: 27px;
          position: absolute;
          right: -15px;
          bottom: 32px;
        }
      }
    }
  }
}
</style>
