<template>
    <div class="securityCenter">
        <div class="containerHead"  v-if="is_navbar == 'true'">
            <NavigationBar title="安全中心"></NavigationBar>
        </div>
        <div  :class="is_navbar=='false' ? 'list-content-active':'list-content'"> 
            <div class="regionContainer" ref="regionContainer" :style="{ opacity: opacityStyle.opacity }">
            </div>
        </div>
        <div :class="{ listProduct: active, listProduct2: active2 }">
            <div class="productListContainer" ref="productListContainer"
                :class="{ active: activeFlag, active2: activeFlag2 }">
                <img :src=barImg alt="" class="barImg">
                <van-tabs background="barImg" title-active-color="#000" title-inactive-color="#fff" v-model="active"
                    @click="onClick">
                    <van-tab title="账号找回公示" name='a'>
                    </van-tab>
                    <van-tab title="立案起诉公示" name='b'>
                    </van-tab>
                </van-tabs>
            </div>
            <div class="productList">
                <AccountRetrievalList v-if="active == 'a'" :is_navbar="is_navbar"></AccountRetrievalList>
                <FilingALawsuitList v-else :is_navbar="is_navbar"></FilingALawsuitList>
            </div>
        </div>
        <!-- <div :class="is_navbar ? 'active-class' : 'inactive-class'"></div> -->
    </div>
</template>

<script>
import { NavigationBar } from "@/components";
import AccountRetrievalList from "@/pages/LeaveTheGame/SecurityCenter/components/AccountRetrievalList.vue";
import FilingALawsuitList from "@/pages/LeaveTheGame/SecurityCenter/components/FilingALawsuitList.vue";

export default {
    name: "SecurityCenter",
    data() {
        return {
            is_navbar: "true",
            opacityStyle: {
                opacity: "",
            },
            barImg: require("@/static/images/securityCenterBar.png"),
            active: "a",
            // 金刚区商品列表
            gameItemList: [],
            activeFlag: false,
            // topstyle1: '',
            // topstyle2: '',
            activeFlag2: false,
            active:true,
            active2:false
        };
    },
    components: {
        NavigationBar,
        AccountRetrievalList,
        FilingALawsuitList,
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        if (JSON.stringify(this.$route.query) != "{}") {
            this.is_navbar = this.$route.query.is_navbar || "true";
        }
        if (this.is_navbar == 'false') {
            this.active = false
            this.active2 = true
        }else{
            this.active = true
            this.active2 = false
        }
    },
    filters: {},
    methods: {
        onClick(active) {
            console.log(active);
            if (active == "a") {
                this.barImg = require("@/static/images/securityCenterBar.png");
                // this.active = 0
                this.opacityStyle.opacity = 1;
            } else {
                this.barImg = require("@/static/images/securityCenterBar2.png");
                this.opacityStyle.opacity = 1;
                // this.active = 1
            }
        },
        handleScroll() {
            const top = document.documentElement.scrollTop || document.body.scrollTop;
            console.log(top);
            if (top > 0) {
                let opacity = 40 / top;
                this.ss = opacity;
                opacity = opacity < 0.1 ? 0 : opacity;
                this.opacityStyle = {
                    opacity,
                };
            }
            if (top > 88 && this.is_navbar == 'true') {
                this.activeFlag = true;
            } else if (top < 88 && this.is_navbar == 'true') {
                this.activeFlag = false;
            }
            if (top > 88 && this.is_navbar == 'false') {
                this.activeFlag2 = true;
            } else if (top < 88 && this.is_navbar == 'false') {
                this.activeFlag2 = false;
            }
        },
        // 标签页
        changTab(game_id) {
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style lang="less" scoped>
.securityCenter {
    min-height: 100vh;
    width: 100%;

    .containerHead {
        width: 100%;
        height: 44px;
        background: #ffffff;
        position: fixed;
        top: 0px;
        z-index: 2;
    }

    .list-content {
        position: absolute;
        top: 44px;
    }
    .regionContainer {
            width: 375px;
            height: 200px;
            background: #fff;
            height: 400px;
            /* 设置内容区域的高度 */
            overflow-y: auto;
            /* 让内容可以滚动 */
            position: relative;
            background: #fff url("../../../static/images/securityCenterBg.png") no-repeat top center;
            background-size: 100%;
            box-sizing: border-box;
        }
    .list-content-active{
        position: absolute;
        top: 0;
    }
    .listProduct {
        width: 375px;
        background: #ffffff;
        border-radius: 20px;
        position: absolute;
        top: 200px;

        .productListContainer {
            // position: relative;
            background: #ffffff;
            //   padding: 0px 20px 4px 20px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            .barImg{
                position: absolute;
                top: 0;
                left: 0;
                // margin-top: 157px;
                width: 375px;
                height: 44px;
            }
        }

        .productList {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
    .listProduct2{
        width: 375px;
        background: #ffffff;
        border-radius: 20px;
        position: absolute;
        top: 156px;
    }
}

.active {
    position: sticky;
    top: 44px;
    z-index: 2;
}
.active2 {
    position: sticky;
    top: 0px;
    z-index: 2;
}


::v-deep .van-tabs--line .van-tabs__wrap {
    border-bottom: 1px solid #f2f2f2;
    border-radius: 16px 16px 0px 0px;
}

::v-deep .van-tab {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
}

::v-deep .van-nav-bar .van-icon {
    color: black;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    background-color: rgba(0, 0, 0, 0) !important;
    border-bottom: 1px solid #fff;
    border-radius: 16px 16px 0px 0px;
}

::v-deep .van-tabs__line {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .van-tabs__nav--line {
    background: transparent;
}

::v-deep .van-tab {
    // position: absolute;
    // top: -44px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    border: none;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    border-bottom: none;
}
.active-class{
    height: 0px;
}
.inactive-class {
    height: 44px;
}
</style>